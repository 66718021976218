const Data =[
    {
        id:1,
        title:'Taxi Service ',
        cover: require('../../Assets/Images/services6.jpg'),
    },
    {
        id:2,
        title:'Outstation Taxi Service ',
        cover: require('../../Assets/Images/services5.jpg')
    },
    {
        id:3,
        title:'One Way Cab Service ',
        cover: require('../../Assets/Images/services4.jpg')
    },
    {
        id:4,
        title:'6 Seater Taxi Service ',
        cover: require('../../Assets/Images/services1.jpg')
    },
    {
        id:5,
        title:'4 Seater Taxi Service',
        cover: require('../../Assets/Images/services2.jpg')
    },
    {
        id:6,
        title:'Car Hire Service',
        cover: require('../../Assets/Images/services3.jpg')
    },
]
export default Data;